import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './i18n';
import "./assets/css/styles.scss";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";

import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module'


function App() {
  
  ReactGA.initialize('G-J1KH63VEX6');
  const tagManagerArgs = {
      //for dev
      // gtmId: 'G-0TYH5KP0XZ',
    
      //for prod
      gtmId: 'GT-TNPXCQC',
  }
  
  TagManager.initialize(tagManagerArgs)
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contactus" element={<ContactUs />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
